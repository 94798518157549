import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";

export default function useAuth() {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    const event = firebase.auth().onAuthStateChanged((u) => {
      setUser(u);
    });

    return () => {
      event();
    };
  }, []);

  return user;
}
