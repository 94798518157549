import * as React from "react";
import { useRecordContext } from "react-admin";

export default function ContractType({ source }) {
  const record = useRecordContext();
  // console.log("record", record);
  // console.log("record[source]", record[source]);
  return record ? (
    <div>{`${
      record[source] === "ZWK1" ? "Value contract" : "Quantity contract"
    } (${record[source]})`}</div>
  ) : null;
}
