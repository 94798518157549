import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from "@material-ui/core";
import eagleIcon from "../../assets/img/eagle.png"; // Tell webpack this JS file uses this image
import DateRange from "../DateRange";

const useStyles = makeStyles({
  container: {
    margin: "1rem 1rem 1rem 1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  controls: {
    display: "flex",
  },
});

export default function Header(props) {
  const periodSelected = (event) => {
    const period = event.target.value;
    props.setSelectedStep(null);
    props.setPeriod(period);
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <Typography variant="h4">
          Eagle <img src={eagleIcon} alt="Logo" style={{ height: 30 }} />
        </Typography>
        <Typography variant="body2">Welcome to the admin dashboard</Typography>
      </div>

      <div className={classes.controls}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          {props.period === "custom" ? (
            <DateRange
              setValue={props.setCustomPeriod}
              value={props.customPeriod}
              align="row"
            />
          ) : (
            <Select
              value={props.period}
              onChange={(event) => {
                periodSelected(event);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="year">Last year</MenuItem>
              <MenuItem value="month">Last month</MenuItem>
              <MenuItem value="week">Last week</MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
            </Select>
          )}
          {props.period !== "custom" && (
            <FormHelperText>Choose period</FormHelperText>
          )}
        </FormControl>
        <FormControl
          sx={{ m: 1, minWidth: 120 }}
          style={{ marginLeft: "1rem" }}
        >
          {props?.salesOrganization &&
            props?.salesOrganizationOptions?.length > 0 && (
              <Select
                value={props.salesOrganization}
                onChange={(event) => {
                  props.setSalesOrganization(event.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {props?.salesOrganizationOptions?.map((org) => (
                  <MenuItem key={org} value={org}>
                    {org}
                  </MenuItem>
                ))}
              </Select>
            )}
          <FormHelperText>Sales Organization</FormHelperText>
        </FormControl>
      </div>
    </div>
  );
}
