import * as React from "react";
import { Show, useEditController, useRefresh } from "react-admin";
import OrderHistory from "./OrderHistory";
import OrderDetails from "./OrderDetails";
import OrderItems from "./OrderItems";
import OrderSettings from "./OrderSettings";
import useAdmin from "../../hooks/useAdmin";

export default function OrderShow(props) {
  const controllerProps = useEditController(props);
  const [isAdmin] = useAdmin();

  // Force refresh when race condition happened when orderlist fetching has finished before ordershow has returned
  const refresh = useRefresh();
  React.useEffect(() => {
    if (!controllerProps?.record?.order) {
      refresh(true);
    }
  }, [controllerProps?.record?.order, refresh]);

  if (!controllerProps?.record) {
    return <p>Loading...</p>;
  }
  const {
    record: { lineItems },
  } = controllerProps;
  const order =
    controllerProps?.record?.salesDocumentCategory?.length > 0
      ? controllerProps?.record // in case list orders returns after order is fetched
      : controllerProps?.record.order;

  return (
    <Show {...props}>
      <>
        {order && <OrderDetails order={order} />}
        <OrderHistory order={order} />
        {lineItems && <OrderItems lineItems={lineItems} />}
        {isAdmin && <OrderSettings order={order} />}
      </>
    </Show>
  );
}
