import * as React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import AddShoppingCartOutlined from "@material-ui/icons/AddShoppingCartOutlined";
import MoneyIcon from "@material-ui/icons/Money";
import GroupIcon from "@material-ui/icons/Group";
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import { makeStyles } from "@material-ui/core/styles";
import currencyToISO from "../../utils/currency";

const AMOUNT_OF_CARDS = 4;
const useStyles = makeStyles({
  container: {
    margin: "0rem 1rem 0rem 1rem",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});
export default function InfoCards(props) {
  const numberFormatter = new Intl.NumberFormat();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {props.fetching ? (
        <>
          {Array.from(Array(AMOUNT_OF_CARDS)).map((_, i) => (
            <Skeleton
              variant="rectangular"
              key={i}
              animation="wave"
              width={420}
              height={145}
            />
          ))}
        </>
      ) : (
        <>
          <Card>
            <CardHeader
              subheader="Orders"
              title={<AddShoppingCartOutlined />}
            />
            <CardContent>
              <b>{numberFormatter.format(props?.totalCnt)} orders</b>
            </CardContent>
          </Card>
          <Card>
            <CardHeader subheader="Revenue" title={<MoneyIcon />} />
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              {props?.revenue?.length ? (
                props?.revenue?.map((rev) => (
                  <b key={rev.currency}>
                    {new Intl.NumberFormat("nl-NL", {
                      style: "currency",
                      currency: currencyToISO(rev.currency),
                    }).format(rev?.sum)}
                  </b>
                ))
              ) : (
                <b>0,00</b>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              subheader="Average sales order value"
              title={<GroupIcon />}
            />
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              {props?.revenue?.length === 1 ? (
                <b key={props?.revenue[0].currency}>
                  {new Intl.NumberFormat("nl-NL", {
                    style: "currency",
                    currency: currencyToISO(props?.revenue[0].currency),
                  }).format(
                    (props?.revenue[0].sum || 0) / (props?.totalCnt || 0)
                  )}
                </b>
              ) : (
                <b>Not available</b>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              subheader="Delivered"
              title={<LocalShippingOutlinedIcon />}
            />
            <CardContent>
              <b>
                {props?.stats?.statusCounts?.delivered
                  ? numberFormatter.format(
                      props?.stats?.statusCounts?.delivered
                    )
                  : "None"}{" "}
                delivered
              </b>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
