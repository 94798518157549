import * as React from "react";
import {
  TopToolbar,
  Button,
  List,
  Datagrid,
  TextField,
  useRecordContext,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DateField } from "../general/dateTimeFields";
import AddressHeader from "../general/addressHeader";
import ContractType from "./contractType";
import { FilterSidebar } from "./filters";
import useAuth from "../../hooks/useAuth";
import { SyncWithSAP } from "../orders/OrderSettings";
import useAdmin from "../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export function CountField({ source }) {
  const record = useRecordContext();
  return record?.[source] ? <div>{record[source]}</div> : "0";
}

function ListActions(props) {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      <Button
        onClick={() => {
          props.handleOpen();
        }}
        label="Add unsynced contract"
      >
        <AddIcon />
      </Button>
    </TopToolbar>
  );
}

export default function ContractList(props) {
  const user = useAuth();
  const [isAdmin] = useAdmin();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (!user) {
    return <p>Loading user...</p>;
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add unsynced contract
          </Typography>
          <SyncWithSAP />
        </Box>
      </Modal>
      <List
        {...props}
        aside={<FilterSidebar />}
        bulkActionButtons={false}
        actions={isAdmin ? <ListActions handleOpen={handleOpen} /> : null}
        sort={{ field: "createdAt", order: "DESC" }}
        style={{ marginTop: "1rem" }}
      >
        <Datagrid optimized rowClick="show">
          <TextField label="Project name" source="description" />
          <TextField
            sortBy="CUSTOMER_PURCHASE_ORDER_NUMBER"
            source="customerPurchaseOrderNumber"
            label="Project ID"
          />
          <TextField label="SalesDocId" source="salesDocumentId" />
          <AddressHeader source="shipToAddress" />
          <ContractType label="Type" source="salesDocumentType" />
          <CountField source="orderCount" label="Orders" />
          <TextField source="status" label="Status" />
          <TextField label="Sold to" source="soldToParty" />
          <DateField source="validTo" />
          <DateField source="lastModifiedAt" />
        </Datagrid>
      </List>
    </>
  );
}
