import * as React from "react";
import { List, Datagrid, TextField, TopToolbar, Button } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import NetValueField from "./netValueField";
import { FilterSidebar } from "./filters";
import useAuth from "../../hooks/useAuth";
import { TimeField } from "../general/dateTimeFields";
import { SyncWithSAP } from "./OrderSettings";
import useAdmin from "../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ListActions(props) {
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      <Button
        onClick={() => {
          props.handleOpen();
        }}
        label="Add unsynced order"
      >
        <AddIcon />
      </Button>
    </TopToolbar>
  );
}

export default function OrderList(props) {
  const user = useAuth();
  const [isAdmin] = useAdmin();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  if (!user) {
    return <p>Loading user...</p>;
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add unsynced order
          </Typography>
          <SyncWithSAP />
        </Box>
      </Modal>
      <List
        {...props}
        aside={<FilterSidebar />}
        bulkActionButtons={false}
        actions={isAdmin ? <ListActions handleOpen={handleOpen} /> : null}
        sort={{ field: "createdAt", order: "DESC" }}
        style={{ marginTop: "1rem" }}
      >
        <Datagrid optimized rowClick="show">
          <TextField sortBy="SALES_DOCUMENT_ID" source="salesDocumentId" />
          <TextField
            sortBy="SALES_ORGANIZATION_ID"
            source="salesOrganizationId"
          />
          <TextField
            sortBy="CURRENCY"
            source="netValue.currency"
            label="Currency"
          />
          <NetValueField sortBy="NET_AMOUNT" sortable label="Amount" />
          <TextField
            sortBy="CUSTOMER_PURCHASE_ORDER_NUMBER"
            source="customerPurchaseOrderNumber"
          />
          <TextField sortBy="SOLD_TO_PARTY" source="soldToParty" />
          <TextField sortBy="CHANNEL" source="channel" />
          <TextField sortBy="STATUS" source="status" />
          <TimeField sortBy="CREATED_AT" source="createdAt" />
        </Datagrid>
      </List>
    </>
  );
}
