/* eslint-disable react/jsx-no-useless-fragment */
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { deCamelCase } from "../../utils/string";
import { tryGetDateTimeStr } from "../../utils/date";
import tryPrintTypeValue from "../../utils/type";

export default function ContractDetails(props) {
  const { contract } = props;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5">{contract.description}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
        <ListItem alignItems="flex-start" style={{ paddingTop: 0 }}>
          <ListItemText
            primary={false}
            secondary={
              <>
                {Object.entries(contract).map((entry, index) => {
                  if (typeof entry[1] === "object") {
                    return (
                      <React.Fragment key={index}>
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {deCamelCase(entry[0])}:
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            left: "17rem",
                            width: "100%",
                          }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {tryPrintTypeValue(entry[1])}
                        </Typography>
                        <br />
                        {/* {Object.entries(entry[1]).map((val) =>
                                typeof val[1] === "object"
                                  ? JSON.stringify(val[1])
                                  : `${val[1]} `
                              )} */}
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment key={index}>
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {deCamelCase(entry[0])}:
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          left: "17rem",
                          width: "100%",
                        }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {tryGetDateTimeStr(entry[1])}
                      </Typography>
                      <br />
                    </React.Fragment>
                  );
                })}
              </>
            }
          />
        </ListItem>
      </AccordionDetails>
    </Accordion>
  );
}
