import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const lineItemColumns = [
  "id",
  "materialId",
  "ean",
  "quantityTarget",
  "quantityUsed",
  "salesNumerator",
  "salesDenominator",
  "salesUnit",
];

export default function ContractItems(props) {
  const { lineItems } = props;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Line items</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              {lineItemColumns.map((li) => (
                <TableCell key={`${li}head`}>{li}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {lineItemColumns.map((li) => {
                  let columnContent = row[li] || "";
                  if (typeof columnContent === "object") {
                    columnContent = Object.entries(columnContent).map(
                      (val) => `${val[1]} `
                    );
                  }
                  return <TableCell key={li}>{columnContent}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}
