/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {
  Create,
  AutocompleteInput,
  TextInput,
  SimpleForm,
  required,
} from "react-admin";
import { Typography } from "@material-ui/core";

function UserCreate({ permissions, ...props }) {
  return (
    <Create {...props}>
      <SimpleForm>
        <Typography variant="h5">Create new user</Typography>
        <TextInput source="email" validate={[required()]} />
        <TextInput source="salesOrganizations" validate={[required()]} />
        <AutocompleteInput
          validate={[required()]}
          source="role"
          choices={[
            { id: "viewer", name: "Viewer" },
            { id: "admin", name: "Admin" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
}

export default UserCreate;
