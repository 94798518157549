import React from "react";
import { Login, LoginForm } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import { GoogleAuthProvider } from "firebase/auth";

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "#/",
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};

function SignInScreen() {
  return (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  );
}
export default function CustomLoginPage(props) {
  return (
    <Login
      {...props}
      backgroundImage="https://source.unsplash.com/random/1600x900/daily"
    >
      <LoginForm {...props} />
      <hr />
      <SignInScreen />
      <div style={{ height: 100 }} /> {/* stretches default login dialog */}
    </Login>
  );
}
