import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/img/logo.svg";

const useStyles = makeStyles({
  logo: {
    filter: "brightness(0) invert(1)",
    height: 50,
  },
});
export default function Logo() {
  const classes = useStyles();
  return <img src={logo} alt="Logo" className={classes.logo} />;
}
