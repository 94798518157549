import firebase from "firebase/compat/app";
import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { GetListParams } from "react-admin";

export const getIdToken = async () => {
  if (!firebase?.auth()?.currentUser) {
    return undefined;
  }
  const idToken = await firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true);
  return idToken;
};

const constructQueryStringByParams = (params: GetListParams): string => {
  const {
    filter: {
      search,
      soldTo,
      channels,
      statuses,
      contractStatus,
      salesOrganizationId,
      dateFrom,
      dateTo,
      steps,
    },
    pagination: { page, perPage: limit } = {},
    sort: { field: orderBy, order: orderDirection } = {},
  } = params;

  const offset = page > 1 ? (page - 1) * (limit || 0) : null;

  // Construct search params based on filters
  const searchParams = new URLSearchParams();
  limit && searchParams.append("limit", limit.toString());
  offset && searchParams.append("offset", offset.toString());
  dateFrom && searchParams.append("dateFrom", dateFrom);
  dateTo && searchParams.append("dateTo", dateTo);
  orderBy && searchParams.append("orderBy", orderBy);
  orderDirection && searchParams.append("orderDirection", orderDirection);
  if (orderBy === "id" && orderDirection === "ASC") {
    searchParams.append("orderBy", "createdAt");
    searchParams.append("orderDirection", "DESC");
  }
  search && searchParams.append("search", search);
  soldTo && searchParams.append("soldTo", soldTo);
  channels && searchParams.append("channels", channels);
  statuses && searchParams.append("statuses", statuses);
  contractStatus && searchParams.append("contractStatus", contractStatus);
  salesOrganizationId &&
    searchParams.append("salesOrganizationId", salesOrganizationId);
  steps && searchParams.append("steps", steps);

  return `?${searchParams.toString()}`;
};
const Provider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson
): DataProvider => ({
  // eslint-disable-next-line consistent-return
  getList: async (resource, params) => {
    // Destructure filter params
    let url = `${apiUrl}/${resource}`;
    const idToken = await getIdToken();

    switch (resource) {
      case "orders":
        if (params) {
          url += constructQueryStringByParams(params);
        }
        return httpClient(url, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({
          data: json?.orders || [],
          total: parseInt(json?.totalCount, 10) || 0,
          stats: {
            channelCounts: json?.channelCounts || {},
            statusCounts: json?.statusCounts || {},
          },
        }));
      case "contracts":
        if (params) {
          url += constructQueryStringByParams(params);
        }
        return httpClient(url, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({
          data: json?.contracts || [],
          total: parseInt(json?.totalCount, 10) || 0,
          stats: json?.statusCounts,
        }));
      case "users":
        return httpClient(url, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) =>
          // const data = json.map((it, index) => ({...it, id: index})); // TODO: add document id to user records in backend
          ({
            data: json || [],
            total: parseInt(json?.length, 10) || 0,
          })
        );
      case "insights":
        if (params) {
          url += constructQueryStringByParams(params);
        }
        return httpClient(url, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => {
          const data = json?.revenue?.map((rev, i) => ({
            id: i,
            ...rev,
            steps: rev?.steps?.map((step) => ({
              ...step,
              step: step?.step || "0",
            })),
          })); // check for step undefined and map to step 0 and add id's to elements
          return {
            data: data || [],
            total: parseInt(json?.revenue?.length, 10) || 0,
          };
        });
    }
  },

  // eslint-disable-next-line consistent-return
  getOne: async (resource, params) => {
    const idToken = await getIdToken();

    switch (resource) {
      case "users":
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({
          data: json || [],
        }));
      case "orders":
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({ data: { ...json, id: json?.order?.id } }));
      case "contracts":
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({ data: { ...json, id: json?.contract?.id } }));
      case "orderChanges":
        return httpClient(`${apiUrl}/orders/${params.id}/changes`, {
          headers: new Headers({
            Authorization: idToken,
          }),
        }).then(({ json }) => ({ data: { ...json, id: json?.order?.id } }));
    }
  },

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
        );
      }
      return {
        data: json,
        total: parseInt(headers.get("x-total-count").split("/").pop(), 10),
      };
    });
  },

  // eslint-disable-next-line consistent-return
  update: async (resource, params) => {
    const idToken = await getIdToken();

    const body = params.data;
    if (resource === "users") {
      body.salesOrganizations =
        typeof body?.salesOrganizations === "string"
          ? body?.salesOrganizations
              .replace(/\s/g, "") // clear spaces
              .split(",") // comma seperate
          : body?.salesOrganizations;
      body.isAdmin = body?.role === "admin";
    }
    const serializedBody = JSON.stringify(body);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: serializedBody,
      headers: new Headers({
        Authorization: idToken,
      }),
    }).then(({ json }) => ({ data: { ...json, id: json.Email } }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: async (resource, params) => {
    const idToken = await getIdToken();
    const {
      data: { salesOrganizations, email, role },
    } = params;
    const body = {
      email: email.replace(/\s/g, ""), // clear spaces,
      salesOrganizations: salesOrganizations
        ?.replace(/\s/g, "") // clear spaces
        ?.split(","), // comma seperate
      isAdmin: role === "admin",
    };

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: new Headers({
        Authorization: idToken,
      }),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: async (resource, params) => {
    const idToken = await getIdToken();
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: idToken,
      }),
    }).then(({ json }) => ({ data: json }));
  },

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

export default Provider;
