// currencyToISO converts SAP currency codes to ISO 4217 codes that are allowed for Intl.NumberFormat(), see https://en.wikipedia.org/wiki/ISO_4217
const currencyToISO = (curr) => {
  switch (curr) {
    case "COP2": // Colombian peso '2', which is used in SAP for some reason
      return "COP";

    case "USDN": // United States dollar (next day)
      return "USN";

    default:
      return curr;
  }
};

export default currencyToISO;
