import * as React from "react";
import { Show, useEditController, useRefresh } from "react-admin";
import ContractDetails from "./ContractDetails";
import ContractItems from "./ContractItems";
import useAdmin from "../../hooks/useAdmin";
import OrderSettings from "../orders/OrderSettings";

export default function ContractShow(props) {
  const controllerProps = useEditController(props);
  const [isAdmin] = useAdmin();

  // Force refresh when race condition happened when orderlist fetching has finished before ordershow has returned
  const refresh = useRefresh();
  React.useEffect(() => {
    if (!controllerProps?.record?.contract) {
      refresh(true);
    }
  }, [controllerProps?.record?.contract, refresh]);

  if (!controllerProps?.record) {
    return <p>Loading...</p>;
  }
  const {
    record: { lineItems },
  } = controllerProps;
  const contract =
    controllerProps?.record?.salesDocumentCategory?.length > 0
      ? controllerProps?.record // in case list orders returns after order is fetched
      : controllerProps?.record.contract;

  return (
    <Show {...props}>
      <>
        {contract && <ContractDetails contract={contract} />}
        {lineItems && <ContractItems lineItems={lineItems} />}
        {isAdmin && <OrderSettings order={contract} />}
      </>
    </Show>
  );
}
