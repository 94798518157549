import {
  endOfYesterday,
  startOfWeek,
  startOfYear,
  subWeeks,
  subYears,
} from "date-fns";

export const applyTimeZone = (stamp) =>
  new Date(stamp.getTime() - stamp.getTimezoneOffset() * 60000);

export const getPeriod = (period) => {
  const date = new Date();

  switch (period) {
    case "month":
      return {
        dateFrom: applyTimeZone(
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        ).toISOString(),
        dateTo: applyTimeZone(
          new Date(date.getFullYear(), date.getMonth(), 1)
        ).toISOString(),
      };
    case "week":
      return {
        dateFrom: applyTimeZone(
          subWeeks(startOfWeek(new Date()), 1)
        ).toISOString(),
        dateTo: applyTimeZone(startOfWeek(new Date())).toISOString(),
      };
    case "today":
      return {
        dateFrom: applyTimeZone(endOfYesterday()).toISOString(),
        dateTo: undefined,
      };
    case "year":
      return {
        dateFrom: applyTimeZone(
          subYears(startOfYear(new Date()), 1)
        ).toISOString(),
        dateTo: applyTimeZone(startOfYear(new Date())).toISOString(),
      };
    default:
      return {
        dateFrom: null,
        dateTo: null,
      };
  }
};

export const parseCustomPeriod = (customPeriod) => ({
  dateFrom: customPeriod[0]
    ? applyTimeZone(customPeriod[0]).toISOString()
    : null,
  dateTo: customPeriod[1] ? applyTimeZone(customPeriod[1]).toISOString() : null,
});

export const getStepByPeriod = (period) => {
  switch (period) {
    case "today":
      return "HOUR";
    case "week":
      return "DAY";
    case "month":
      return "WEEK";
    case "YEAR":
      return "WEEK"; // TODO: apply changes to butler to be able to use year
    default:
      return "DAY";
  }
};

export const tryGetDateTimeStr = (dtCandidate) => {
  const dtms = Date.parse(dtCandidate);
  if (
    // Test if the string is in RFC 3339 format. Note: isNaN(Date.parse(...)) is not good enough, see e.g. https://stackoverflow.com/a/7445368 for cases that are parsed
    /^((?:(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}(?:\.\d+)?))(Z|[+-]\d{2}:\d{2})?)$/.test(
      String(dtCandidate)
    ) &&
    !isNaN(dtms)
  ) {
    const dt = new Date(dtms);
    return dt.toUTCString();
  }
  return dtCandidate;
};
