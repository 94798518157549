import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { syncSAP } from "./sync";

export function SyncWithSAP(props) {
  const [method, setMethod] = useState("SLIMSHADY");
  const [salesDocId, setSalesDocId] = useState(
    props?.order?.salesDocumentId || ""
  );
  const [syncResult, setSyncResult] = useState();
  return (
    <Box>
      <Box sx={{ minWidth: 120, marginBottom: 2 }}>
        {!props?.order?.salesDocumentId && ( // Only show input if no salesDocumentId is set on prop (used in sync settings on order/contract detail page)
          <FormControl sx={{ mb: 2, mt: 2, width: "25ch" }} variant="outlined">
            <TextField
              id="outlined-multiline-flexible"
              label="Sales document ID"
              multiline
              maxRows={4}
              value={salesDocId}
              onChange={(e) => setSalesDocId(e.target.value)}
            />
          </FormControl>
        )}
        <FormControl fullWidth>
          <InputLabel id="Method-l">Method</InputLabel>
          <Select
            labelId="Method-l"
            id="method"
            value={method}
            label="method"
            onChange={(e) => setMethod(e.target.value)}
          >
            <MenuItem value="SLIMSHADY">
              SlimShady database (recommended)
            </MenuItem>
            <MenuItem value="BAPI">BAPI call</MenuItem>
            <MenuItem value="SCAN_TABLE">
              ScanTable (least recommended, except for contracts)
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Button
        onClick={async () => {
          const res = await syncSAP(salesDocId, method);
          if (res) {
            setSyncResult(res?.error || "Success");
          }
        }}
        variant="contained"
      >
        Sync using {method}
      </Button>
      {syncResult && (
        <Typography
          variant="body1"
          sx={{
            marginTop: 1,
            color: syncResult?.includes("err") ? "red" : "green",
          }}
        >
          {syncResult}
        </Typography>
      )}
    </Box>
  );
}

export default function OrderSettings(props) {
  const { order } = props;

  if (!order?.salesDocumentId) {
    return null;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Sync from SAP</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SyncWithSAP order={order} />
      </AccordionDetails>
    </Accordion>
  );
}
