import * as React from "react";
import { useRecordContext } from "react-admin";

export function TimeField({ source }) {
  const record = useRecordContext();
  return record ? <div>{new Date(record[source]).toLocaleString()}</div> : null;
}

export function DateField({ source }) {
  const record = useRecordContext();
  return record ? <div>{new Date(record[source]).toDateString()}</div> : null;
}
