import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import useAuth from "./useAuth";

export default function useAdmin() {
  const [isAdmin, setIsAdmin] = useState(undefined);
  const dP = useDataProvider();
  const authUser = useAuth();

  useEffect(() => {
    if (!authUser) {
      return;
    }
    async function fetchData() {
      const { data: user } = await dP.getOne("users", { id: "self" });
      setIsAdmin(user?.isAdmin);
    }
    fetchData();
  }, [dP, authUser]);
  return [isAdmin, authUser];
}
