import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";

export const primaryColor = "#00A8E1";
export const secondaryColor = "#279046";
export const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: "#B2E5F6",
      main: "#00A8E1",
      dark: "#244b90",
      contrastText: "#fff",
    },
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Helvetica Neue",
      "Arial",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: primaryColor,
        },
      },
    },
    MuiFilledInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid ${primaryColor}`,
        },
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: `1px solid ${primaryColor}`,
        },
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 20,
        },
      },
    },
  },
});
