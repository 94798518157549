import * as React from "react";
import { useRecordContext } from "react-admin";

export default function AddressHeader({ source }) {
  const record = useRecordContext();
  return record[source]?.street1 ? (
    <div>{`${record[source]?.street1}, ${record[source]?.city}`}</div>
  ) : (
    <div>No address</div>
  );
}
