import * as React from "react";
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";

export default function DateRange(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Start"
        endText="End"
        value={props?.value}
        onChange={(newValue) => {
          props?.setValue(newValue);
        }}
        renderInput={(startProps, endProps) => {
          if (props.align === "row") {
            return (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            );
          }
          return (
            <div>
              <hr />
              <TextField {...startProps} style={{ marginBottom: "1rem" }} />
              <TextField {...endProps} />
            </div>
          );
        }}
      />
    </LocalizationProvider>
  );
}
