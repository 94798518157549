import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { FirebaseAuthProvider } from "react-admin-firebase";

export default function useFirebase() {
  const [authProvider, setAuthProvider] = useState(undefined);

  useEffect(() => {
    let firebaseConfig;
    if (process.env.REACT_APP_ENV === "local") {
      firebaseConfig = {
        apiKey: "AIzaSyAoJhhXAvNBJZ2clr2rqBeVNotN5uUoTiw",
        projectId: "wavin-digital-apiplatform-h9w",
        appId: "1:550170821578:web:21acf038988287fa960533",
        authDomain: "wavin-digital-apiplatform-h9w.firebaseapp.com",
        databaseURL: "https://wavin-digital-apiplatform-h9w.firebaseio.com",
        messagingSenderId: "550170821578",
        storageBucket: "wavin-digital-apiplatform-h9w.appspot.com",
      };
    } else {
      firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    }

    setAuthProvider(FirebaseAuthProvider(firebaseConfig, null));
    firebase.initializeApp(firebaseConfig);
  }, []);

  return authProvider;
}
