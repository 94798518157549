import { getIdToken } from "../../providers/dataProvider";

// eslint-disable-next-line import/prefer-default-export
export async function syncSAP(salesDocId, method) {
  const idToken = await getIdToken();
  try {
    const res = await fetch(
      `${process.env.REACT_APP_DOMAIN}/orders/sync_specific`,
      {
        method: "POST",
        headers: {
          Authorization: idToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sapOrigin: "emea-ecc",
          salesDocumentId: salesDocId,
          method,
        }),
      }
    );
    return res.json();
  } catch (error) {
    return null;
  }
}
