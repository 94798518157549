import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import useAuth from "./useAuth";

export default function useUser() {
  const [user, setUser] = useState();
  const dataProvider = useDataProvider();
  const authUser = useAuth();
  // Get user
  useEffect(() => {
    if (!authUser) {
      return;
    }
    const getUser = async () => {
      const res = await dataProvider.getOne("users", { id: "self" });
      setUser(res?.data as any);
    };
    getUser();
  }, [dataProvider, authUser]);
  return user;
}
