/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import { Children, cloneElement } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
  useRecordContext,
  TopToolbar,
  CreateButton,
} from "react-admin"; // eslint-disable-line import/no-unresolved

function CustomLabelField({ source, sortable }) {
  const record = useRecordContext();
  if (Array.isArray(record?.salesOrganizations)) {
    return (
      <div>
        {record.salesOrganizations.map((so) => (
          <Chip key={so} label={so} />
        ))}
      </div>
    );
  }
  return (
    <div>
      {record.salesOrganizations.split(",").map((so) => (
        <Chip key={so} label={so} />
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    maxWidth: "20em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  publishedAt: { fontStyle: "italic" },
}));

const useToolbarStyle = makeStyles({
  toolbar: {
    alignItems: "center",
    display: "flex",
    marginTop: -1,
    marginBottom: -1,
  },
});

function UserListActionToolbar({ children, ...props }) {
  const classes = useToolbarStyle();
  return (
    <div className={classes.toolbar}>
      {Children.map(children, (button) => cloneElement(button, props))}
    </div>
  );
}

function ListActions(props) {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  );
}

function UserList(props) {
  const classes = useStyles();
  return (
    <List
      {...props}
      actions={<ListActions />}
      pagination={false}
      style={{ marginTop: "1rem" }}
    >
      <Datagrid rowClick="edit" optimized>
        <TextField
          source="email"
          sortable={false}
          cellClassName={classes.title}
        />

        <BooleanField source="isAdmin" label="Admin" sortable={false} />
        <CustomLabelField source="salesOrganizations" sortable={false} />
        <UserListActionToolbar>
          <EditButton />
        </UserListActionToolbar>
      </Datagrid>
    </List>
  );
}

export default UserList;
