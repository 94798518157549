import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Chip from "@mui/material/Chip";
import { compareAsc, format, parse } from "date-fns";
import { useDataProvider } from "react-admin";

function ChangeTypeChip(props) {
  const { change } = props;

  switch (change?.changeType) {
    case "UPDATE":
      return (
        <Chip
          label="Update"
          style={{ backgroundColor: "#00A8E1", color: "white" }}
        />
      );
    case "INSERT":
      return <Chip label="Created" color="success" />;
    case "DELETE":
      return <Chip label="Removed" color="error" />;
    default:
      return <Chip label="New" color="success" />;
  }
}

function EntryHeader(props) {
  const { change } = props;

  return (
    <>
      <h3 style={{ marginTop: 0 }}>{format(change?.changedAt, "PPpp")}</h3>
      <ChangeTypeChip change={change} />
      <Typography component="span" variant="body1" style={{ marginLeft: 10 }}>
        {change?.changedBy} applied {change?.changeItems?.length} changes
      </Typography>
    </>
  );
}
export default function OrderHistory(props) {
  const { order } = props;
  const [changes, setChanges] = useState(undefined);
  const [expended, setExpended] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!order || !expended) {
      return;
    }
    const getOrderChanges = async () => {
      const res = await dataProvider.getOne("orderChanges", { id: order?.id });
      const unorderedChanges = res?.data?.changes;
      if (!(unorderedChanges?.length > 0)) {
        setChanges({});
        return;
      }
      const mappedWithDates = unorderedChanges.map((change) => {
        return {
          ...change,
          changedAt: parse(change.changedAt, "yyyy-M-d H:m:s", new Date()),
        };
      });
      const orderedChanges = mappedWithDates.sort((a, b) =>
        compareAsc(a.changedAt, b.changedAt)
      );
      setChanges(orderedChanges);
    };
    getOrderChanges();
  }, [dataProvider, order, expended]);

  return (
    <Accordion onChange={(event, ex) => setExpended(ex)}>
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Order Changes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List style={{ paddingTop: 0 }}>
          {changes?.length > 0 ? (
            changes?.map((change) => {
              return (
                <ListItem alignItems="flex-start" key={change.changeDocumentId}>
                  <ListItemText
                    primary={<EntryHeader change={change} />}
                    secondary={
                      <>
                        {change.changeItems.map((item, index) => {
                          return (
                            <React.Fragment
                              key={item.tableName + item.columnName + index}
                            >
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                style={{ marginLeft: 75, fontWeight: 300 }}
                              >
                                {item?.action
                                  ? `- ${item.action} `
                                  : `- ${item.columnName} changed `}
                              </Typography>
                              from{" "}
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.oldValue ? item.oldValue : "empty"}
                              </Typography>{" "}
                              to{" "}
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {item?.newValue ? item.newValue : "empty"}
                              </Typography>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                style={{ fontWeight: 300, fontStyle: "italic" }}
                              >
                                {` (${item.tableName}.${item.columnName})`}
                              </Typography>
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </>
                    }
                  />
                </ListItem>
              );
            })
          ) : (
            <span>{changes === undefined ? "Loading..." : "No changes"}</span>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
