import * as React from "react";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// import StoreIcon from "@material-ui/icons/StoreOutlined";
import GroupIcon from "@material-ui/icons/Group";
import TrafficIcon from "@material-ui/icons/TrafficOutlined";
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";
import { startOfWeek, startOfMonth, subMonths } from "date-fns";
import { applyTimeZone, getPeriod } from "../../utils/date";
import useUser from "../../hooks/useUser";
import { capitalizeFirstLetter } from "../../utils/string";
import DateFilter from "../filters/DateFilter";

// TODO: DRY this up for orders and contracts
const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export function FilterSidebar() {
  return (
    <Card>
      <CardContent>
        <FilterLiveSearch source="search" />
        <FilterLiveSearch source="soldTo" label="Sold to" />
        <SalesOrgFilter />
        <WhenOrderedFilter />
        <StatusesFilter />
      </CardContent>
    </Card>
  );
}

export function WhenOrderedFilter() {
  return (
    <FilterList label="When ordered" icon={<AccessTimeIcon />}>
      <FilterListItem label="Today" value={getPeriod("today")} />
      <FilterListItem
        label="This week"
        value={{
          dateFrom: applyTimeZone(startOfWeek(new Date())).toISOString(),
          dateTo: undefined,
        }}
      />
      <FilterListItem label="Last week" value={getPeriod("week")} />
      <FilterListItem
        label="This month"
        value={{
          dateFrom: applyTimeZone(startOfMonth(new Date())).toISOString(),
          dateTo: undefined,
        }}
      />
      <FilterListItem label="Last month" value={getPeriod("month")} />
      <FilterListItem
        label="Earlier"
        value={{
          dateFrom: undefined,
          dateTo: applyTimeZone(
            subMonths(startOfMonth(new Date()), 1)
          ).toISOString(),
        }}
      />
      <DateFilter />
    </FilterList>
  );
}

export function SalesOrgFilter() {
  const user = useUser();
  return (
    <FilterList label="Sales organizations" icon={<GroupIcon />}>
      {user?.salesOrganizations &&
        user.salesOrganizations.map((so) => (
          <FilterListItem
            label={so}
            key={so}
            value={{
              salesOrganizationId: so,
            }}
          />
        ))}
    </FilterList>
  );
}

const statuses = ["OPEN", "CLOSED", "INVISIBLE"];
export function StatusesFilter() {
  return (
    <FilterList label="Status" icon={<TrafficIcon />}>
      {statuses.map((status) => (
        <FilterListItem
          key={status}
          label={capitalizeFirstLetter(status.toLowerCase())}
          value={{ contractStatus: status }}
        />
      ))}
    </FilterList>
  );
}
