import * as React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@mui/material/Skeleton";
import { capitalizeFirstLetter } from "../../utils/string";

function DetailsPieChart(props) {
  const COLORS = ["#80217e", "#d06531", "#244b90", "#279046"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%  `}
      </text>
    );
  };
  if (props.fetching) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={420}
        height={250}
      />
    );
  }

  if (!props?.stats) {
    return null;
  }
  const numberFormatter = new Intl.NumberFormat();
  const data = Object.keys(props?.stats).map((key, index) => ({
    name: key,
    value: parseInt(props?.stats[key], 10),
  }));
  return (
    <Card style={{ ...props.style, ...{ width: "100%" } }}>
      <Typography variant="h5" style={{ margin: 10 }}>
        {props.title}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <PieChart width={200} height={200}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
          {Object.keys(props?.stats).map((key, index) => (
            <div key={index}>
              <Typography variant="body1">
                {capitalizeFirstLetter(key)}
              </Typography>
              <span
                style={{
                  backgroundColor: COLORS[index % COLORS.length],
                  height: 10,
                  width: 10,
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              />{" "}
              <b>{numberFormatter.format(props.stats[key])}</b>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default DetailsPieChart;
