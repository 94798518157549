import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const lineItemColumns = [
  {
    key: "id",
    header: "ID",
  },
  {
    key: "materialId",
    header: "Material",
  },
  {
    key: "ean",
    header: "EAN",
  },
  {
    key: "quantity",
    header: "Qty",
  },
  {
    key: "quantityConfirmed",
    header: "Qty confirmed",
  },
  {
    key: "salesNumerator",
    header: "Num",
  },
  {
    key: "salesDenominator",
    header: "Denom",
  },
  {
    key: "salesUnit",
    header: "Unit",
  },
  {
    key: "netValue",
    header: "Net value",
  },
  {
    key: "pricePerQuantity",
    header: "Price/qty",
  },
  {
    key: "category",
    header: "Category",
  },
];

export default function OrderItems(props) {
  const { lineItems } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">Line items</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              {lineItemColumns.map((li) => (
                <TableCell key={`${li.key}head`}>{li.header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {lineItemColumns.map((li) => {
                  let columnContent = row[li.key] || "";
                  if (typeof columnContent === "object") {
                    columnContent = Object.entries(columnContent).map(
                      (val) => `${val[1]} `
                    );
                  }
                  return <TableCell key={li}>{columnContent}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
}
