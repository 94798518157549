/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import RevenueChart from "./RevenueChart";
import DetailsPieChart from "./DetailsPieChart";
import InfoCards from "./InfoCards";
import Header from "./Header";
import {
  getPeriod,
  getStepByPeriod,
  parseCustomPeriod,
} from "../../utils/date";
import useAuth from "../../hooks/useAuth";

const styles = {
  flexColumn: {
    display: "flex",
    height: 700,
    flexDirection: "column",
    width: 1300,
  },
  flexRow: { display: "flex", flexDirection: "row", height: "100%" },
  leftCenterColumn: {
    flex: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

export default function Dashboard(props) {
  // const classes = useStyles();
  const dataProvider = useDataProvider();
  const [fetching, setFetching] = useState(["orders", "insights"]);
  const [orders, setOrders] = useState(null);
  const [user, setUser] = useState();
  const [insights, setInsights] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [salesOrganization, setSalesOrganization] = useState();
  const [period, setPeriod] = useState("month");
  const [customPeriod, setCustomPeriod] = useState([null, null]);

  const authUser = useAuth();

  // Get user
  useEffect(() => {
    if (!authUser) {
      return;
    }

    const getUser = async () => {
      const res = await dataProvider.getOne("users", { id: "self" });
      setSalesOrganization(res?.data?.salesOrganizations[0]); // Set first sales org as default
      setUser(res?.data);
    };
    getUser();
  }, [dataProvider, authUser]);

  // Get orders
  useEffect(() => {
    // Check if sign-in in progress
    if (!user) {
      return;
    }
    // Check if custom period is being selected
    if (period === "custom" && !customPeriod?.[0]) {
      return;
    }

    // Raise flag of fetching orders
    fetching.indexOf("orders") === -1 &&
      setFetching((curFetch) => [...curFetch, "orders"]);

    const getList = async () => {
      const dateTimeFiler =
        period === "custom"
          ? parseCustomPeriod(customPeriod)
          : getPeriod(period);
      const res = await dataProvider.getList("orders", {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: "createdAt",
          order: "DESC",
        },
        filter: { ...dateTimeFiler, salesOrganizationId: salesOrganization },
      });
      setOrders(res);

      // Remove orders fetching flag
      setFetching((curFetch) => curFetch.filter((item) => item !== "orders"));
    };
    getList();
  }, [dataProvider, period, customPeriod, user, salesOrganization]);

  // Get Revenue
  useEffect(() => {
    // Check if sign-in in progress
    if (!user) {
      return;
    }
    // Check if custom period is being selected
    if (period === "custom" && !customPeriod?.[0]) {
      return;
    }

    // Raise flag of fetching insights
    fetching.indexOf("insights") === -1 &&
      setFetching((curFetch) => [...curFetch, "insights"]);

    const getStats = async () => {
      const dateTimeFiler =
        period === "custom"
          ? parseCustomPeriod(customPeriod)
          : getPeriod(period);
      const res = await dataProvider.getList("insights", {
        filter: {
          ...dateTimeFiler,
          salesOrganizationId: salesOrganization,
          steps: selectedStep || getStepByPeriod(period),
        },
      });
      setInsights(res?.data);

      // Remove orders fetching flag
      setFetching((curFetch) => curFetch.filter((item) => item !== "insights"));
    };
    getStats();
  }, [
    dataProvider,
    period,
    customPeriod,
    salesOrganization,
    user,
    selectedStep,
  ]);

  return (
    <div style={styles.flexColumn}>
      {/* Header */}
      <Header
        setPeriod={setPeriod}
        period={period}
        setCustomPeriod={setCustomPeriod}
        setSelectedStep={setSelectedStep}
        customPeriod={customPeriod}
        setSalesOrganization={setSalesOrganization}
        salesOrganization={salesOrganization}
        salesOrganizationOptions={user?.salesOrganizations}
        hasOrders={orders?.total}
      />
      <div style={styles.flexRow}>
        {/* Center charts */}
        <div style={styles.leftCenterColumn}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <DetailsPieChart
              stats={orders?.stats?.channelCounts}
              title="Channels"
              fetching={fetching.includes("orders")}
              style={{ marginRight: 10 }}
            />
            <DetailsPieChart
              stats={orders?.stats?.statusCounts}
              title="Status"
              fetching={fetching.includes("orders")}
            />
          </div>
          <RevenueChart
            insights={insights}
            period={period}
            fetching={fetching.includes("insights")}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
          />
        </div>
        {/* Sidebar */}
        <InfoCards
          stats={orders?.stats}
          revenue={insights}
          fetching={["orders", "insights"].some((e) => fetching.includes(e))}
          totalCnt={orders?.total}
        />
      </div>
      {/* Footer */}
    </div>
  );
}
