import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  Line,
} from "recharts";
import {
  Card,
  CardContent,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from "@material-ui/core";
import { Paper } from "@mui/material";
import { getStepByPeriod } from "../../utils/date";
import currencyToISO from "../../utils/currency";

const getStepLabel = (selectedStep, period, step) => {
  const activeStep = selectedStep || getStepByPeriod(period);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  switch (activeStep) {
    case "MONTH":
      return monthNames[step - 1];
    case "WEEK":
      return `Week ${step}`;
    case "DAY":
      return `${step}/${new Date().getMonth() + 1}`;
    case "HOUR":
      return `${step}:00`;
    default:
      return step;
  }
};

export function Header(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "10px 10px 0 10px",
      }}
    >
      <Typography variant="h5">Revenue</Typography>
      <div>
        {props?.insights?.length > 1 && (
          <FormControl sx={{ m: 1, minWidth: 120 }} style={{ marginRight: 15 }}>
            <Select
              value={props?.selectedInsight.currency}
              onChange={(event) => {
                const currency = event?.target?.value;
                const selectedInsight = props?.insights.find(
                  (el) => el.currency === currency
                );
                props.setSelectedInsight(selectedInsight);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {props.insights.map((insight) => (
                <MenuItem value={insight?.currency} key={insight?.currency}>
                  {insight?.currency}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Currency</FormHelperText>
          </FormControl>
        )}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={props?.selectedStep || getStepByPeriod(props?.period)}
            onChange={(event) => {
              props.setSelectedStep(event?.target?.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="HOUR">Hour</MenuItem>
            <MenuItem value="DAY">Day</MenuItem>
            <MenuItem value="WEEK">Week</MenuItem>
            <MenuItem value="MONTH">Month</MenuItem>
            <MenuItem value="YEAR">Year</MenuItem>
          </Select>
          <FormHelperText>Choose interval</FormHelperText>
        </FormControl>
      </div>
    </div>
  );
}

function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <div style={{ margin: 10 }}>
          <b>{label}</b>
          <p>{payload[0].payload.formatted}</p>
        </div>
      </Paper>
    );
  }

  return null;
}

export default function RevenueChart(props) {
  const [selectedInsight, setSelectedInsight] = React.useState();
  React.useEffect(() => {
    setSelectedInsight(props?.insights?.[0]);
  }, [props.insights]);

  if (props.fetching) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={845}
        height={300}
      />
    );
  }
  return (
    <Card>
      <Header
        {...props}
        selectedInsight={selectedInsight}
        setSelectedInsight={setSelectedInsight}
      />
      <CardContent>
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart
            width={500}
            height={200}
            data={selectedInsight?.steps?.map((d) => ({
              ...d,
              value: +d.value,
              step: `${getStepLabel(
                props?.selectedStep,
                props?.period,
                d?.step
              )}`,
              formatted: new Intl.NumberFormat("nl-NL", {
                style: "currency",
                currency: currencyToISO(selectedInsight?.currency),
              }).format(d.value),
            }))}
            syncId="anyId"
            margin={{
              top: 0,
              right: 10,
              left: 35,
              bottom: 0,
            }}
            onClick={(e) => {}}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="step" />
            <YAxis dataKey="value" />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#279046"
              fill="#66BC88"
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="#8884d8"
              strokeDasharray="5 5"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
