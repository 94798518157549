/* eslint-disable new-cap */
/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */

const tryPrintTypeValue = (valueObj) => {
  if (valueObj === undefined) {
    return 'undefined';
  }
  if (valueObj?.value && valueObj?.currency) {
   // it's a wavin_juicer_MonetaryValue obj:
   return `${valueObj.value} ${valueObj.currency}`;
 }
  if (valueObj?.value && valueObj?.unit?.isoCode) {
    // unit/weight obj:
    return `${valueObj.value} ${valueObj.unit.isoCode}`;
  }

  if (valueObj?.street1){
    // address
    return `${valueObj.name1} - ${valueObj.street1} ${valueObj.postalCode}  ${valueObj.city} ${valueObj.country}`;
  }

  if (valueObj?.emailAddress1 && valueObj?.telephoneNumber1) {
    // employee resposibile
    return `${valueObj.name1} - ${valueObj.emailAddress1} | ${valueObj.telephoneNumber1} `;
  }

  if (valueObj?.name1 || valueObj?.telephoneNumber1) {
    // contact person
    return `${valueObj.name1 || 'Unknown'} - ${valueObj.telephoneNumber1 || 'Unknown'} `;
  }
  
  return JSON.stringify(valueObj);
};
export default tryPrintTypeValue;
