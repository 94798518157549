import * as React from "react";
import { useRecordContext } from "react-admin";
import currencyToISO from "../../utils/currency";

export default function NetValueField() {
  const record = useRecordContext();
  return record?.netValue ? (
    <div>
      {new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: currencyToISO(record?.netValue?.currency),
      }).format(record?.netValue?.value)}
    </div>
  ) : null;
}
