import React, { useState } from "react";
import { ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useListFilterContext } from "ra-core";
import DateRange from "../DateRange";
import { parseCustomPeriod } from "../../utils/date";

const useStyles = makeStyles({
  listItem: {
    paddingLeft: "2em",
  },
  listItemText: {
    margin: 0,
  },
});

function DateFilter(props) {
  const classes = useStyles(props);
  const { setFilters } = useListFilterContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [customPeriod, setCustomPeriod] = useState([null, null]);

  const applyFilter = (period) => {
    setFilters(parseCustomPeriod(period));
    setCustomPeriod(period);
  };
  if (dialogOpen) {
    return <DateRange setValue={applyFilter} value={customPeriod} />;
  }

  return (
    <ListItem
      button
      onClick={() => setDialogOpen(true)}
      className={classes.listItem}
    >
      <ListItemText
        primary="Custom date range"
        className={classes.listItemText}
      />
    </ListItem>
  );
}
export default DateFilter;
