import * as React from "react";
import { Resource, AdminUI, AdminContext } from "react-admin";
import GroupIcon from "@material-ui/icons/Group";
import Assignment from "@material-ui/icons/Assignment";
import serverProvider from "./providers/dataProvider";

// Components
import { myTheme } from "./components/myTheme";
import MyLayout from "./components/myLayout";
import Dashboard from "./components/dashboard/Dashboard";
import CustomLoginPage from "./components/LoginPage";
import useAuth from "./hooks/useAuth";
import useAdmin from "./hooks/useAdmin";
import useFirebase from "./hooks/useFirebase";
import OrderList from "./components/orders/OrderList";
import OrderShow from "./components/orders/OrderShow";
import ContractList from "./components/contracts/ContractList";
import ContractShow from "./components/contracts/ContractShow";
import UserEdit from "./components/users/UserEdit";
import UserCreate from "./components/users/UserCreate";
import UserList from "./components/users/UserList";

const dataProvider = serverProvider(process.env.REACT_APP_DOMAIN);

function AsyncResources() {
  const [isAdmin, authUser] = useAdmin();
  if (isAdmin === undefined && authUser === undefined) {
    return <p>Loading...</p>;
  }
  const resources = [
    <Resource name="orders" key="orders" list={OrderList} show={OrderShow} />,
    <Resource
      name="contracts"
      key="contracts"
      list={ContractList}
      show={ContractShow}
      icon={Assignment}
    />,
    // <Resource
    //   name="products"
    //   key="products"
    //   list={ProductList}
    //   show={ProductList}
    //   icon={Assignment}
    // />,
  ];
  isAdmin &&
    resources.push(
      <Resource
        name="users"
        key="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={GroupIcon}
      />
    );

  return (
    <AdminUI
      dashboard={Dashboard}
      loginPage={CustomLoginPage}
      layout={MyLayout}
      theme={myTheme}
    >
      {resources}
    </AdminUI>
  );
}

function App() {
  const authProvider = useFirebase();
  React.useEffect(() => {
    if (["local", "test"].some((e) => window.location.href.includes(e))) {
      // Set page title and favicon to dev when in local or testing mode
      document.title = "Dev - Eagle";
      document.querySelector("link[rel~='icon']").href = "favicon_dev.ico";
    }
  }, []);
  const authUser = useAuth();
  if (authUser === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <AdminContext
      initialState={{ admin: { ui: { sidebarOpen: false } } }}
      authProvider={authProvider}
      theme={myTheme}
      layout={MyLayout}
      dataProvider={dataProvider}
    >
      <AsyncResources />
    </AdminContext>
  );
}

export default App;
