/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {
  Edit,
  TextInput,
  EditActions,
  SimpleForm,
  useEditController,
  AutocompleteInput,
  required,
} from "react-admin"; // eslint-disable-line import/no-unresolved
import { Typography } from "@material-ui/core";

function UserEdit({ permissions, ...props }) {
  const controllerProps = useEditController(props);
  if (!controllerProps?.record) {
    return <p>Loading...</p>;
  }
  const { record } = controllerProps;
  record.role = record?.isAdmin ? "admin" : "viewer";
  return (
    <Edit title={`Edit ${record.email}`} actions={<EditActions />} {...props}>
      <SimpleForm>
        <Typography variant="h5">{record.email}</Typography>
        <TextInput
          label="SalesOrganizations"
          source="salesOrganizations"
          validate={[required()]}
        />
        <AutocompleteInput
          validate={[required()]}
          source="role"
          choices={[
            { id: "viewer", name: "Viewer" },
            { id: "admin", name: "Admin" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}

export default UserEdit;
